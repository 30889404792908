<!-- 薪资发放 -->
<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="举办单位">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.companyId">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="item in companyMap" :key="item.id" :value="item.id">{{item.companyName}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="培训标题">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.title" />
                </a-form-item>
                <a-form-item label="培训种类">
                    <a-select class="search-select" placeholder="请选择" v-model="searchInfo.type">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="item in trainTypeList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="培训日期">
                    <a-range-picker class="search-range-date" v-model="searchInfo.endDate" />
                </a-form-item>
                
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button class="item" @click="exprotFile('safeEducationExcel',{params:searchInfo,dateArray:['startDate','endDate']})"><a-icon type="export" />导出</a-button>
                <router-link class="item" to="/ssms/education/train/add">
                    <a-button type="primary" >添加培训</a-button>
                </router-link>
                
            </a-row>
        </section>
        <section class="list-single-container">
            <a-row class="list-border list-item m-t-18 list-item-image" v-for="(item,key) in listMap" :key="key">
                <a-row class="action-btn-group">
                    <a-row>
                        <router-link class="m-b-8" :to="{path:'/ssms/education/train/details',query:{id:item.id,companyName:item.companyName}}"><a-button size="small">查看</a-button></router-link>
                        <router-link class="m-b-8" :to="{path:'/ssms/education/train/edit',query:{id:item.id}}"><a-button size="small">编辑</a-button></router-link>
                        <a-popconfirm @confirm="deleteItem(item.id)" title="确定删除该培训记录?"><a-button size="small">删除</a-button></a-popconfirm>
                    </a-row>
                </a-row>
                <a-row class="image-wrap">
                    <img :src="item.imageUrl" alt="">
                </a-row>
                <p class="list-title"><span :title="item.title">{{(item.title && item.title.length > 50) ? (item.title.substring(0,50)+ '···'): item.title}}</span></p>
                <a-row class="m-b-10">
                    <a-col :span="7">
                        <span class="key">举办单位：</span>
                        <span class="val">{{item.companyName}}</span>
                    </a-col>
                    <!-- <a-col :span="7">培训日期：{{item.dateStartTime}} - {{item.dateEndTime}}</a-col> -->
                    <a-col :span="7">
                        <span class="key">培训日期：</span>
                        <span class="val">{{item.dateStartTime}}</span>
                    </a-col>
                    <a-col :span="7">
                        <span class="key">参与人数：</span>
                        <span class="val">{{item.personCount}}</span>
                    </a-col>
                </a-row>
                <a-row class="m-b-10">
                    <a-col :span="7">
                        <span class="key">培训种类：</span>
                        <span class="val">{{item.typeName}}</span>
                    </a-col>
                    <a-col :span="7">
                        <span class="key">开始时间：</span>
                        <span class="val">{{item.startTime}}</span>
                    </a-col>
                    <a-col :span="7">
                        <span class="key">上传人员：</span>
                        <span class="val">{{item.createByName}}</span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="7">
                        <span class="key">培训地点：</span>
                        <span class="val" :title="item.addr">{{(item.addr && item.addr.length > 25) ? item.addr.substring(0,25)+ '···': item.addr}}</span>
                    </a-col>
                    <a-col :span="7">
                        <span class="key">结束时间：</span>
                        <span class="val">{{item.endTime}}</span>
                    </a-col>
                    <a-col :span="7">
                        <span class="key">上传时间：</span>
                        <span class="val">{{item.createTime}}</span>
                    </a-col>
                </a-row>
            </a-row>
        </section>
        <section class="pagination-container">
            <a-pagination 
            size="default" 
            :current="pageNum" 
            :total="total" 
            :hideOnSinglePage="true" 
            :show-total="(total) => `共${total}条`"
            :showQuickJumper="true"
		    :showSizeChanger="false"
            @change="changePageNum"/>
        </section>
    </section>
</template>

<script>
import {exprotFile} from '@/until/unit'
export default {
    data(){
        return{

            total:0,
            pageNum:1,
            listMap:[],
            searchInfo:{
                endDate:[]
            },
            companyMap:[],
            trainTypeList:[]
        }
    },
    created(){
        this.init()
    },
    mounted(){
        this.querySafeEducationList()
    },
    computed:{
        picServer(){
            return this.$store.state.picServer
        }   
    },
    methods:{
        exprotFile,
        init(){
            this.querySafeEducationTypeList()
            this.queryCompanyDropDownBox()
        },
        //单位下拉
        queryCompanyDropDownBox(){
            this.$api.queryCompanyDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyMap = res.data || []
                }
            })
        },
        //培训类型
        querySafeEducationTypeList(){
            this.$api.querySafeEducationTypeList().then(res =>{
                if(res.code === 200){
                    this.trainTypeList = (res.data && res.data.records) || []
                    
                }
            })
        },
        querySafeEducationList(){
            let params = {...this.searchInfo}
            
            params.current = this.pageNum
            params.startDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : ''
            params.endDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
            this.$api.querySafeEducationList(params).then(res =>{
                if(res.code === 200){
                    this.listMap = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                    this.listMap.forEach(item =>{
                        //item.imageUrl = (item.photo && item.photo.length) ? JSON.parse(item.photo)[0].url : require('../../../../assets/image/icon/default-image.png')
                        item.photo = item.photo ? item.photo.split(',')[0] : ''
                        item.imageUrl = item.photo ? (item.photo.includes('http') ? item.photo : (this.picServer + item.photo)) : require('../../../../assets/image/icon/default-image.png')
                    })
                    
                }
            })
        },
        //重置
        reset(){
            this.searchInfo = {
                endDate:[]
            }
            this.pageNum = 1
            this.querySafeEducationList()
        },
        search(){
            this.pageNum = 1
            this.querySafeEducationList()
        },
        //分页
        changePageNum(p){
            this.pageNum = p
            this.querySafeEducationList()
        },
        //删除
        deleteItem(id){
            this.$api.deleteSafeEducation(id).then(res =>{
                if(res.code === 200){
                    this.querySafeEducationList()
                }
            })
        }
    }
}
</script>